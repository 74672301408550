
import React, { useEffect, useState } from "react";
import "./eventSlider.css";

import EventCarousel from "./EventCarousel.js";
import SmallCarousel from "./SmallCarousel.js";
import axios from "axios";
import { USERAPIURL } from "../Constants";
/* test */

const EventSlider = () => {

  const [isMobile, setMobile] = useState(document.body.clientWidth <= 498);
  
  let carousel;

  if (isMobile) {
    carousel = <SmallCarousel />;
  } else {
    carousel = <EventCarousel />;
  }

  return (
    <>
    <section className="joining-section">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="joining-heading">
                WHY YOU SHOULD JOIN <br /> OUR EVENT ?
              </h1>
            </div>
          </div>
          
        </div>
        <div className="row mt-5">
          <div className="col-lg-12">
            {carousel}
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default EventSlider;
