import React from 'react'

import Navbar from './Navbar';

import "./hersec.css"
import Spotting from './Spotting';
import Intro from './Intro';

function HeroSection() {

  return (
    <>
      <Navbar />
      
        <Spotting/>
      
    </>
  )
}

export default HeroSection;
