import React from 'react'
import Events from './Events';
import EventSlider from './EventSlider'
import Footer from './Footer';
import HeroSection from './HeroSection';
import LandingPage from './LandingPage'
import PreviousEvents from './PreviousEvents';
import Banner from './Banner';

function Home() {

  return (
    <>
      <HeroSection />
      <LandingPage />
      <Banner />
      <EventSlider />
      <PreviousEvents />
      <Events />
      
      <Footer />
    </>
  )
}

export default Home
