import background01 from './Components/images/background.png'
import background02 from './Components/images/background02.png'
import background03 from './Components/images/background03.png'

const images = {
    background01,
    background02,
    background03
}

export default images 